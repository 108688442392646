html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
input,
textarea,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
body {
  line-height: 1;
  color: #000;
  background: #fff;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
a img {
  border: none;
}
* {
  font-display: optional;
  -webkit-font-smoothing: subpixel-antialiased;
}
body,
html {
  font-family: sans-serif;
  font: 18px 'PT Sans', Verdana, Helvetica, Arial, sans-serif;
  line-height: 1.25em;
  color: rgba(0,0,0,0.95);
}
@media only screen and (min-width: 800px) {
  body,
  html {
    line-height: 1.375em;
  }
}
h1,
.alpha,
h2,
.beta,
h3,
.gamma,
h4.tetta {
  font-family: 'Roboto Slab', serif;
}
a {
  color: #a0082c;
  border-bottom: 1px solid rgba(188,10,52,0.2);
  text-decoration: none;
  transition: all 0.3s ease-out;
  display: inline;
}
ahover {
  color: #cd0b39;
  text-decoration: none;
  border-bottom-color: rgba(205,11,57,0.5);
}
ahover .underlined {
  color: #cd0b39;
  text-decoration: none;
  border-bottom-color: rgba(205,11,57,0.5);
}
a.no-border {
  border: none;
}
a.no-borderhover {
  border: none;
}
.underlined {
  display: inline !important;
  text-decoration: none;
  transition: all 0.3s ease-out;
  border-bottom: 1px solid rgba(188,10,52,0.2);
}
.underlinedhover {
  text-decoration: none;
  border-bottom-color: rgba(205,11,57,0.5);
}
h1.underlined,
.alpha.underlined,
h2.underlined,
.beta.underlined,
h3.underlined,
.gamma.underlined,
h4.tetta {
  border-bottom-width: 2px;
}
.strong,
strong {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.stroked {
  text-decoration: line-through;
}
.text__color_red {
  color: #bc0a34;
}
.text__color_text {
  color: rgba(0,0,0,0.95);
}
h1,
h2,
h3,
h4 {
  content: "";
  clear: both;
  display: table;
}
h1,
.alpha {
  font-size: 1.85em;
  line-height: 1.25em;
  max-width: 60rem;
  display: block;
}
@media only screen and (min-width: 600px) {
  h1,
  .alpha {
    font-size: 2.5em;
  }
}
@media only screen and (min-width: 800px) {
  h1,
  .alpha {
    font-size: 3em;
    line-height: 1.35;
  }
}
.alpha.hero {
  margin-top: 0;
  margin-bottom: 2em;
}
h2,
.beta {
  font-size: 1.625em;
  line-height: 1.15384615em;
  margin-top: 1.730769225em;
}
@media only screen and (min-width: 600px) {
  h2,
  .beta {
    font-size: 2em;
    line-height: 1.25;
    max-width: 35rem;
  }
}
@media only screen and (min-width: 800px) {
  h2,
  .beta {
    font-size: 2.25em;
  }
}
h3,
.gamma {
  font-size: 1.175em;
  line-height: 1.13636364em;
  display: block;
  margin-top: 1.70454546em;
}
@media only screen and (min-width: 600px) {
  h3,
  .gamma {
    font-size: 1.2em;
    line-height: 1.25;
    max-width: 35rem;
  }
}
@media only screen and (min-width: 800px) {
  h3,
  .gamma {
    font-size: 1.4em;
  }
}
p {
  margin-top: 1.25em;
  display: block;
}
@media only screen and (min-width: 600px) {
  p {
    max-width: 45em;
    line-height: 1.375em;
  }
}
p img {
  max-width: 100%;
  height: auto;
}
p.lead,
.lead {
  font-size: 1.1rem;
  font-family: 'Roboto Slab', sans-serif;
}
.js__link,
.js-link {
  cursor: pointer;
  color: rgba(0,0,0,0.8);
  border-bottom: 1px solid rgba(0,0,0,0.2);
}
.js__linkhover,
.js-linkhover {
  color: #cd0b39;
  border-bottom: 1px solid rgba(205,11,57,0.2);
}
.small {
  font-size: 0.9em;
}
.phone__code {
  font-size: 0.8em;
  color: rgba(0,0,0,0.8);
}
a .phone__code {
  border-bottom: none;
}
.phone__number {
  margin-left: 0.3em;
}
ul,
.text-list,
ol,
.full-article ul {
  margin-top: 1.25em;
}
@media only screen and (min-width: 600px) {
  ul,
  .text-list,
  ol,
  .full-article ul {
    max-width: 45em;
  }
}
ul li + li,
.text-list li + li,
ol li + li,
.full-article ul li + li {
  margin-top: 0.9375em;
}
ul li:before,
.text-list li:before,
ol li:before,
.full-article ul li:before {
  content: "\2014";
  margin-left: -1em;
}
ul li,
.text-list li,
ol li,
.full-article ul li {
  margin-left: 1.3em;
}
ol {
  list-style-type: decimal;
}
ol li:before {
  content: "";
  margin: 0;
}
.suggestions-constraints li:before {
  content: "";
  margin: 0;
}
p + .text-list,
p+ul,
p+ol,
.full-article p + ul,
.gamma + ul,
.gamma+ol {
  margin-top: 0;
}
@media only screen and (min-width: 600px) {
  .full-article {
    line-height: 1.45;
  }
  .full-article p,
  .full-article ul,
  .full-article blockquote,
  .full-article ol {
    font-size: 20px;
  }
}
.full-article p img {
  float: left;
  margin-right: 1.25em;
  margin-bottom: 0.625em;
}
.full-article blockquote {
  margin-top: 1.25em;
  border-radius: 6px;
  background-color: #fcf2c8;
  padding: 1.25em;
}
@media only screen and (min-width: 600px) {
  .full-article blockquote {
    max-width: 35em;
  }
}
.full-article blockquote p:first-child {
  margin-top: 0;
}
table.table {
  margin-top: 1.25em;
  max-width: 35em;
}
table.table tr td {
  vertical-align: top;
  padding: 0.625em 0.625em;
}
table.table tr tdfirst-child {
  padding-left: 0;
}
table.table tr tdlast-child {
  padding-right: 0;
}
table.table tr td.number {
  text-align: right;
}
img.img-fluid {
  max-width: 100%;
  height: auto;
}
img.img-left {
  float: left;
  margin-right: 1.25em;
  margin-bottom: 1.25em;
}
img.img-right {
  float: right;
  margin-left: 1.25em;
  margin-bottom: 1.25em;
}
.video-container {
  position: relative;
  padding-bottom: 56.25% /* 169 */;
  padding-top: 1.25em;
  margin-top: 1.25em;
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.speech-bubble {
  position: relative;
  background: #fff;
  border-radius: 1em;
  color: rgba(0,0,0,0.95);
  padding: 1.25em;
}
.speech-bubble_right:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-left-color: #fff;
  border-right: 0;
  margin-top: -16px;
  margin-right: -16px;
}
.speech-bubble_left:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-right-color: #fff;
  border-left: 0;
  margin-top: -16px;
  margin-left: -16px;
}
.form {
  display: flex;
  flex-direction: column;
}
.hidden_on_mobile {
  display: none;
}
@media only screen and (min-width: 600px) {
  .hidden_on_mobile {
    display: block;
  }
}
.form_highlighted {
  background-color: #fcf2c8;
  padding: 0 1.25em 1.875em;
  border-radius: 6px;
  margin-bottom: 2rem;
  box-shadow: 0 1px 4px rgba(135,55,0,0.4);
}
@media only screen and (min-width: 1050px) {
  .form_highlighted {
    padding: 0 2.5em 3.75em;
  }
}
@media only screen and (max-width: 1800px) {
  .form_highlighted h3 {
    margin-top: 1.25em;
  }
}
.form__group,
.form-group {
  display: flex;
  flex-direction: column;
  margin-top: 1.25em;
}
label {
  line-height: 1.25em;
}
input[type="radio"] {
  line-height: 1.25em;
}
label+input,
label+textarea,
label+.file-inputs {
  margin-top: 0.3125em;
}
label {
  line-height: 1.25em;
  font-size: 0.8em;
  position: absolute;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
}
label.file-label {
  line-height: 1.25em;
  font-size: 1em;
  position: relative;
  margin-top: 0;
  margin-left: 0;
}
.form__control,
.form-control {
  border: 1px solid #eee;
  padding: 1.8rem 0.5rem 0.5rem;
  border-radius: 6px;
  background-color: #fff;
  display: block;
  width: 100%;
  transition: all 0.21s ease-in;
}
.form__control:focus,
.form-control:focus {
  border-color: #444;
  outline: none;
  box-shadow: 0px 1px 7px rgba(92,14,81,0.54);
}
.form__control[type="file"],
.form-control[type="file"] {
  border: none;
  padding: 0 0 0.5rem;
  font-size: 1rem;
}
.form__control.valid,
.form-control.valid {
  border: 1px solid #1daf19;
}
.form__control.error,
.form-control.error {
  border: 1px solid #d90303;
}
input[type="submit"] {
  margin-top: 1.25em;
}
input[type="file"] {
  background: transparent;
}
.form__radio {
  flex-direction: row;
  align-items: center;
}
.form__radio label {
  flex: 0 1 80%;
  margin-left: 0.3rem;
  display: block;
}
.form__group_sliding {
  display: block;
  margin-bottom: 1.375rem;
}
.form__group_sliding > label {
  display: block;
  transition: all 0.25s ease-out;
  transform: translate(0, -1.375rem);
  font-size: 1rem;
  color: rgba(51,51,51,0.95);
}
.form__group_sliding .form__control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 0.3rem 0rem 0.1rem;
}
.form__group_sliding .form__control:focus {
  box-shadow: none;
}
.form__group_sliding .form__control:focus + label {
  transform: translate(0, -2.75rem);
  font-size: 0.8rem;
  color: rgba(102,102,102,0.95);
}
.form__group_sliding .form__control.valid {
  box-shadow: none;
  border-bottom: 1px solid #1daf19;
}
.form__group_sliding .form__control.valid + label {
  transform: translate(0, -2.75rem);
  font-size: 0.8rem;
  color: rgba(102,102,102,0.95);
}
.form__group_sliding textarea {
  height: 2rem;
}
label.error {
  color: #d90303;
  padding-top: 0.3125em;
}
.form__address {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
.address__street {
  flex: 0 0 100%;
}
@media only screen and (min-width: 600px) {
  .address__street {
    flex: 0 0 50%;
  }
}
.address__house {
  flex: 0 0 55%;
  margin-right: 1rem;
}
@media only screen and (min-width: 600px) {
  .address__house {
    margin-left: 1rem;
    flex: 0 0 30%;
  }
}
.address__office {
  flex: 1 0 30%;
}
@media only screen and (min-width: 600px) {
  .address__office {
    flex: 1 0 15%;
  }
}
.form__footer {
  text-align: center;
  margin: 2rem 0;
}
.btn {
  -webkit-appearance: none;
  background-color: #bc0a34;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(188,10,52,0.5);
  color: #fff;
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  text-decoration: none;
  box-sizing: border-box;
  border: none;
  text-align: center;
  display: inline-block;
  align-self: center;
  transition: color, box-shadow, background-color 0.31s ease-out;
  transition: transform 0.31s cubic-bezier(0.2, 3, 0.4, 1);
  border: none;
}
.btn:hover {
  background-color: #cd0b39;
  text-decoration: none;
  box-shadow: 0px 3px 6px rgba(205,11,57,0.5);
  transform: scale(1.07, 1.07);
}
.btn:active {
  background-color: #ae0930;
  text-decoration: none;
  box-shadow: 0px 1px 1px rgba(41,2,11,0.8);
  transition: transform 0.1s ease-in;
  transform: scale(0.95, 0.95);
}
.btn_sm {
  font-size: 1rem;
  border-radius: 3px;
  padding: 0.3rem 0.8rem;
}
.btn_inline {
  font-size: 1rem;
  display: inline;
  border-radius: 3px;
  padding: 0.15rem 0.45em;
}
.btn.is-disabled {
  background-color: rgba(128,128,128,0.95);
  box-shadow: none;
}
.btn.is-disabled:hover {
  background-color: rgba(128,128,128,0.95);
  box-shadow: none;
  transform: scale(0.95, 0.95);
}
button.btn {
  margin-top: 1.25em;
}
a.btn {
  color: #fff;
}
a.btn:hover {
  color: #fff;
}
.btn.btn_light {
  background-color: none;
  border: 2px solid #fff;
}
.btn.btn_light:hover {
  color: #bc0a34;
  background-color: #fff;
  text-decoration: none;
  transform: scale(1.07, 1.07);
}
.btn.btn_light:active {
  color: #bc0a34;
  background-color: none;
  text-decoration: none;
  transition: transform 0.1s ease-in;
  transform: scale(0.95, 0.95);
}
.btn.btn_outline {
  background-color: #fff;
  color: #bc0a34;
  border: 1px solid #bc0a34;
}
.btn.btn_outline:hover {
  color: #fff;
  background-color: #bc0a34;
  text-decoration: none;
  transform: scale(1.07, 1.07);
}
.btn.btn_outline:active {
  color: #fff;
  background-color: #bc0a34;
  text-decoration: none;
  transition: transform 0.1s ease-in;
  transform: scale(0.95, 0.95);
}
.preloader {
  margin: 0 auto;
  left: 0;
  right: 0;
  height: 40px;
  width: 6px;
  background-color: transparent;
  animation: paddles 0.75s ease-out infinite;
  transform: translate3d(0, 0, 0);
}
.preloader:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15px;
  width: 10px;
  height: 10px;
  background-color: #bc0a34;
  border-radius: 50%;
  animation: ballbounce 0.6s ease-out infinite;
}
.preloader_hidden {
  display: none;
}
@-moz-keyframes paddles {
  0% {
    box-shadow: -25px -10px 0px #bc0a34, 25px 10px 0px #bc0a34;
  }
  50% {
    box-shadow: -25px 8px 0px #bc0a34, 25px -10px 0px #bc0a34;
  }
  100% {
    box-shadow: -25px -10px 0px #bc0a34, 25px 10px 0px #bc0a34;
  }
}
@-webkit-keyframes paddles {
  0% {
    box-shadow: -25px -10px 0px #bc0a34, 25px 10px 0px #bc0a34;
  }
  50% {
    box-shadow: -25px 8px 0px #bc0a34, 25px -10px 0px #bc0a34;
  }
  100% {
    box-shadow: -25px -10px 0px #bc0a34, 25px 10px 0px #bc0a34;
  }
}
@-o-keyframes paddles {
  0% {
    box-shadow: -25px -10px 0px #bc0a34, 25px 10px 0px #bc0a34;
  }
  50% {
    box-shadow: -25px 8px 0px #bc0a34, 25px -10px 0px #bc0a34;
  }
  100% {
    box-shadow: -25px -10px 0px #bc0a34, 25px 10px 0px #bc0a34;
  }
}
@keyframes paddles {
  0% {
    box-shadow: -25px -10px 0px #bc0a34, 25px 10px 0px #bc0a34;
  }
  50% {
    box-shadow: -25px 8px 0px #bc0a34, 25px -10px 0px #bc0a34;
  }
  100% {
    box-shadow: -25px -10px 0px #bc0a34, 25px 10px 0px #bc0a34;
  }
}
@-moz-keyframes ballbounce {
  0% {
    transform: translateX(-20px) scale(1, 1.2);
  }
  25% {
    transform: scale(1.2, 1);
  }
  50% {
    transform: translateX(15px) scale(1, 1.2);
  }
  75% {
    transform: scale(1.2, 1);
  }
  100% {
    transform: translateX(-20px);
  }
}
@-webkit-keyframes ballbounce {
  0% {
    transform: translateX(-20px) scale(1, 1.2);
  }
  25% {
    transform: scale(1.2, 1);
  }
  50% {
    transform: translateX(15px) scale(1, 1.2);
  }
  75% {
    transform: scale(1.2, 1);
  }
  100% {
    transform: translateX(-20px);
  }
}
@-o-keyframes ballbounce {
  0% {
    transform: translateX(-20px) scale(1, 1.2);
  }
  25% {
    transform: scale(1.2, 1);
  }
  50% {
    transform: translateX(15px) scale(1, 1.2);
  }
  75% {
    transform: scale(1.2, 1);
  }
  100% {
    transform: translateX(-20px);
  }
}
@keyframes ballbounce {
  0% {
    transform: translateX(-20px) scale(1, 1.2);
  }
  25% {
    transform: scale(1.2, 1);
  }
  50% {
    transform: translateX(15px) scale(1, 1.2);
  }
  75% {
    transform: scale(1.2, 1);
  }
  100% {
    transform: translateX(-20px);
  }
}
.scrollable {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
}
.scrollable::-webkit-scrollbar {
  display: none;
}
.scrollable::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4rem;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0.001) 0%, #fff 96%) /* transparent keyword is broken in Safari */;
}
@media only screen and (min-width: 1050px) {
  .scrollable {
    white-space: normal;
    overflow-y: visible;
    overflow-x: visible;
  }
  .scrollable::after {
    content: none;
  }
}
.header {
  padding-bottom: 1.25em;
  padding-top: 1rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 800px) {
  .header {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 1.25em;
    justify-content: space-between;
  }
}
.logo__link,
.logo {
  text-decoration: none;
  border: none;
}
.logo__link:hover,
.logo:hover {
  border: none;
  text-decoration: none;
}
.logo__title {
  color: #bc0a34;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.25em;
  letter-spacing: 0.15rem;
}
@media only screen and (min-width: 400px) {
  .logo__title {
    font-size: 1.4rem;
  }
}
.logo__lead {
  color: #555;
  font-size: 0.6rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  line-height: 2;
}
@media only screen and (min-width: 400px) {
  .logo__lead {
    font-size: 0.75rem;
  }
}
.logo {
  width: auto;
}
.logo_large {
  height: 5rem;
}
@media only screen and (max-width: 800px) {
  .logo_large {
    display: none;
  }
}
.logo_small {
  height: 2.5rem;
}
@media only screen and (min-width: 800px) {
  .logo_small {
    display: none;
  }
}
@media only screen and (min-width: 800px) {
  .header__logo {
    flex: 0 1 45%;
  }
}
.header__work-time {
  display: none;
}
@media only screen and (min-width: 600px) {
  .header__work-time {
    margin-top: 0.625em;
    display: flex;
  }
}
@media only screen and (min-width: 800px) {
  .header__work-time {
    flex: 0 1 30%;
    flex-direction: column;
    text-align: right;
    margin-top: 0;
  }
}
.header__contacts {
  margin-top: 0.625em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
@media only screen and (min-width: 800px) {
  .header__contacts {
    flex: 0 1 50%;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: right;
    margin-top: 0;
  }
}
.header__contacts .contact {
  display: block;
  margin-right: 1.25em;
  margin-bottom: 1.25em;
}
@media only screen and (min-width: 600px) {
  .header__contacts .contact {
    margin-right: 1.25em;
  }
}
@media only screen and (min-width: 800px) {
  .header__contacts .contact {
    width: 50%;
    margin-right: 0;
    margin-bottom: 0.625em;
  }
  .header__contacts .contact +.call-back-link {
    width: 100%;
  }
  .header__contacts .contact +.cart-control {
    width: 100%;
  }
}
.telegram-logo {
  height: 1rem;
  vertical-align: text-top;
}
.whatsapp-logo {
  height: 1rem;
  vertical-align: text-top;
}
.header__contacts div.fast-order-link {
  display: none;
}
@media only screen and (min-width: 1050px) {
  .header__contacts div.fast-order-link {
    display: block;
  }
}
.call-back-link {
  display: none;
}
@media only screen and (min-width: 600px) {
  .call-back-link {
    margin-top: 0;
    display: block;
  }
}
@media only screen and (min-width: 800px) {
  .call-back-link {
    width: 100%;
  }
}
@media only screen and (min-width: 1050px) {
  .call-back-link {
    margin-top: 0.416666666666667em;
  }
}
.header__contacts li.contacts__email {
  display: none;
}
@media only screen and (min-width: 600px) {
  .header__contacts li.contacts__email {
    display: block;
  }
}
.footer {
  padding: 0 1rem 2.5em;
  color: rgba(77,77,77,0.95);
  margin-top: 2.5em;
  font-size: 0.8rem;
  display: flex;
  align-items: flex-start;
}
@media only screen and (min-width: 800px) {
  .footer {
    margin-top: 3.75em;
  }
}
@media only screen and (min-width: 1050px) {
  .footer {
    padding: 0 0 2.5em;
  }
}
.footer__item {
  margin: 0;
}
.footer__item:before {
  content: "";
  margin: 0;
}
a.footer__link {
  color: rgba(77,77,77,0.95);
  border-bottom-color: rgba(0,0,0,0.2);
}
a.footer__link:hover {
  color: #cd0b39;
  border-bottom-color: rgba(205,11,57,0.2);
}
.footer__address-link-title {
  flex: 1 0 100%;
  display: block;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0.3125em;
}
.footer__company-links {
  padding-right: 3.75em;
}
@media only screen and (min-width: 1050px) {
  .footer__company-links {
    flex: 0 0 25%;
    padding-right: 0;
  }
}
.footer__info-links {
  padding-right: 3.75em;
}
@media only screen and (min-width: 1050px) {
  .footer__info-links {
    flex: 0 0 25%;
    padding-right: 0;
  }
}
.footer__address-links {
  padding-left: 1.25em;
  flex: 0 0 50%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer__address-link-list {
  padding: 0;
  margin: 0;
  flex: 0 1 50%;
}
.reviews-showcase {
  margin-top: 2.5em;
  display: flex;
  align-content: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  background-color: #b0ea7b;
}
@media only screen and (min-width: 600px) {
  .reviews-showcase {
    padding-bottom: 3rem;
    padding-top: 2rem;
  }
}
@media only screen and (min-width: 1050px) {
  .reviews-showcase {
    padding: 2.5rem 3.5rem 3.5rem;
    margin-top: 5rem;
  }
}
.reviews-showcase__talk {
  flex: 0 0 100%;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 0;
}
@media only screen and (min-width: 600px) {
  .reviews-showcase__talk {
    margin-bottom: 3.5rem;
    font-size: 3rem;
    max-width: 45em;
  }
}
.reviews-showcase__link {
  margin-top: 1rem;
  font-size: 1rem;
  flex: 0 0 100%;
}
@media only screen and (min-width: 600px) {
  .reviews-showcase__link {
    font-size: 2rem;
    margin-top: 2rem;
  }
}
.reviews-showcase__side {
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (min-width: 600px) {
  .reviews-showcase__side {
    flex: 0 0 45%;
  }
}
.review {
  margin-bottom: 1rem;
}
.review:last-child {
  margin-bottom: 0;
}
.review_highlighted {
  flex: 0 0 100%;
}
@media only screen and (min-width: 600px) {
  .review_highlighted {
    flex: 0 0 48%;
    width: 48%;
  }
}
.review__body {
  font-size: 1rem;
}
.review__body p:last-child {
  margin-bottom: 0.25rem;
}
.review__body_highlighted {
  line-height: 1.375;
}
@media only screen and (min-width: 600px) {
  .review__body_highlighted {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1050px) {
  .review__body_highlighted {
    font-size: 2rem;
  }
}
.review__author {
  font-weight: bold;
  display: inline-block;
}
.review__author + p {
  margin-top: 0;
}
.review__date {
  font-style: italic;
  color: rgba(38,38,38,0.95);
}
.benefits__wrapper {
  background-color: #bc0a34;
  color: #fff;
}
.benefits {
  padding-top: 1.375em;
  padding-bottom: 1.375em;
}
@media only screen and (min-width: 600px) {
  .benefits {
    padding-top: 2.75em;
    padding-bottom: 2.75em;
  }
}
@media only screen and (min-width: 800px) {
  .benefits {
    padding-top: 4.125em;
    padding-bottom: 4.125em;
  }
}
@media only screen and (min-width: 600px) {
  .benefits__main {
    flex: 0 0 45%;
  }
}
@media only screen and (min-width: 600px) {
  .benefits__warranty {
    flex: 0 0 45%;
  }
}
.benefits__title {
  font-size: 1.2rem;
}
@media only screen and (min-width: 400px) {
  .benefits__title {
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 600px) {
  .benefits__title {
    font-size: 2.4rem;
  }
}
.speech-bubble_left {
  transform: translateX(-40px);
}
.speech-bubble_right {
  transform: translateX(40px);
}
.hidden {
  opacity: 0;
}
.visible {
  opacity: 1;
  transform: none;
  transition: all 0.8s ease-in-out;
}
.popup__overlay {
  position: fixed;
  top: -1000%;
  left: -1000%;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1001;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
.popup__overlay.active {
  top: 0;
  left: 0;
  opacity: 0.8;
}
.popup__window {
  position: fixed;
  top: -200%;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0;
  z-index: 1002;
  overflow-y: auto;
  max-height: 80%;
  transition: top 400ms cubic-bezier(0.19, 0.01, 0.39, 1);
  transition-timing-function: cubic-bezier(0.19, 0.01, 0.39, 1);
}
@media only screen and (min-width: 600px) {
  .popup__window {
    left: 50%;
    width: 500px;
    bottom: auto;
    right: auto;
    margin-left: -250px;
    border: 16px solid #ffa500;
  }
}
@media only screen and (min-width: 800px) {
  .popup__window {
    width: 600px;
    margin-left: -300px;
  }
}
.popup__window.active {
  top: 0;
  bottom: 0;
  opacity: 1;
}
@media only screen and (min-width: 600px) {
  .popup__window.active {
    top: 10%;
    bottom: auto;
  }
}
.popup__window .error {
  color: #f00;
}
.popup__wrapper {
  margin: 1rem;
}
@media only screen and (min-width: 600px) {
  .popup__wrapper {
    margin: 3rem;
  }
}
.popup__title {
  margin-top: 0;
}
.popup__close {
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 30px;
  font-size: 2em;
  color: #666;
  cursor: pointer;
}
.popup__close:hover {
  color: #333;
}
.popup__lead {
  margin-bottom: 2rem;
}
.postomat {
  margin-top: 2.5em;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1050px) {
  .postomat {
    flex-direction: row;
    align-items: center;
  }
}
.postomat__text-wrapper {
  color: #fff;
  background-color: #ff6d01;
  background: linear-gradient(#ff6d01, #ff6d01);
  order: 1;
  padding: 1.25em;
  margin-top: -5em;
}
@media only screen and (min-width: 600px) {
  .postomat__text-wrapper {
    margin-top: -7.5em;
    padding: 2.5em 2.5em 2.5em 40%;
  }
}
@media only screen and (min-width: 1050px) {
  .postomat__text-wrapper {
    margin-top: 5rem;
    margin-left: -50%;
    padding: 0 0 0 40%;
  }
}
@media only screen and (min-width: 1050px) {
  .postomat__text {
    padding: 2.5em;
  }
}
@media only screen and (min-width: 600px) {
  .postomat__title {
    margin-top: 0;
  }
}
.postomat__image img {
  max-width: 100%;
  height: auto;
}
@media only screen and (min-width: 1050px) {
  .postomat__image {
    flex: 0 0 75%;
  }
}
.container::after {
  content: '';
  display: table;
  clear: both;
}
@media only screen and (min-width: 1050px) {
  .container {
    max-width: 1020px;
    width: 1020px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (min-width: 1280px) {
  .container {
    max-width: 1200px;
    width: 1200px;
  }
}
.container_padded {
  padding-left: 1rem;
  padding-right: 1rem;
}
@media only screen and (min-width: 1050px) {
  .container_padded {
    padding-left: 0;
    padding-right: 0;
  }
}
.container_form {
  padding: 0.1rem 1rem 1rem;
}
@media only screen and (min-width: 600px) {
  .container_form {
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 6px;
    padding: 0.1rem 2rem 2rem;
    margin-top: 1.25em;
  }
}
@media only screen and (min-width: 1050px) {
  .container_form {
    padding: 0.1rem 2rem 2rem;
  }
}
.container__color_yellow {
  background-color: #fcf2c8;
}
@media only screen and (min-width: 600px) {
  .container__body {
    width: 60%;
    overflow: visible;
    padding: 0;
  }
}
@media only screen and (min-width: 600px) {
  .container__side-note {
    float: left;
    left: 100%;
    margin-right: -100%;
    padding-left: 0;
    width: 53.5475234270415%;
    margin-left: 14.7%;
    clear: both;
    box-sizing: border-box;
    position: relative;
  }
}
.row::after {
  content: '';
  display: table;
  clear: both;
}
.col-3 {
  float: left;
  clear: none;
  text-align: inherit;
  width: 31.33333333333333%;
  margin-left: 0%;
  margin-right: 3%;
}
.col-3::after {
  content: '';
  display: table;
  clear: both;
}
.col-3:last-child {
  margin-right: 0%;
}
.columns {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 800px) {
  .columns {
    flex-direction: row;
  }
}
@media only screen and (min-width: 800px) {
  .columns_center {
    align-items: center;
  }
}
@media only screen and (min-width: 800px) {
  .columns_two > .column {
    flex: 0 0 50%;
    padding-right: 1.25em;
    padding-left: 1.25em;
  }
  .columns_two > .column:last-child {
    padding-right: 0;
  }
  .columns_two > .column:first-child {
    padding-left: 0;
  }
}
@media only screen and (min-width: 800px) {
  .columns_two > .column-1-3 {
    flex: 0 0 33%;
    padding-right: 1.25em;
    padding-left: 1.25em;
  }
  .columns_two > .column-1-3:last-child {
    padding-right: 0;
  }
  .columns_two > .column-1-3:first-child {
    padding-left: 0;
  }
}
@media only screen and (min-width: 800px) {
  .columns_two > .column-2-3 {
    flex: 0 0 67%;
    padding-right: 1.25em;
    padding-left: 1.25em;
  }
  .columns_two > .column-2-3:last-child {
    padding-right: 0;
  }
  .columns_two > .column-2-3:first-child {
    padding-left: 0;
  }
}
@media only screen and (min-width: 800px) {
  .columns_three > .column {
    flex: 0 1 auto;
    padding-right: 1.25em;
    padding-left: 1.25em;
  }
  .columns_three > .column:last-child {
    padding-right: 0;
  }
  .columns_three > .column:first-child {
    padding-left: 0;
  }
}
.hidden {
  display: none !important;
  opacity: 0 !important;
}
.file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fcf2c8;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease-in;
}
.file:hover {
  background-color: #ffe164;
}
a:hover > .file__size {
  text-decoration: none;
}
.file__icon {
  margin-right: 0.5rem;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
}
.file__download-icon {
  margin-left: 0.5rem;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url("/bem/dist/i/arrow.png");
  background-position: left center;
}
.file__icon_png {
  background-image: url("/bem/dist/i/png-icon.png");
}
.file__name {
  flex-grow: 2;
}
.file__size {
  color: rgba(77,77,77,0.95);
  font-style: italic;
  text-decoration: none;
}
.digital-copy .header__wrapper,
.digital-copy .container__wrapper {
  background-color: #f5f5f5;
}
.digital-copy .container__wrapper {
  padding-top: 3rem;
}
.digital-copy-animation {
  position: relative;
  width: 597px;
  max-width: 100%;
  flex-shrink: 0;
}
.digital-copy-animation__fader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70%;
  background: linear-gradient(to top, rgba(255,255,255,0) 0%, #f5f5f5 100%);
}
.digital-copy-animation__image {
  max-width: 100%;
  height: auto;
}
.digital-copy-container {
  padding-top: 1rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 600px) {
  .digital-copy-container {
    flex-direction: row;
    align-items: flex-end;
  }
}
.digital-copy-title {
  padding: 0 3rem 0 0;
}
.shipping-block {
  display: flex;
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
@media only screen and (min-width: 600px) {
  .shipping-block {
    margin-top: 0;
    margin-bottom: 0;
    width: 40%;
  }
}
.shipping-block__icon {
  margin-right: 1rem;
  order: 1;
}
@media only screen and (min-width: 600px) {
  .shipping-block__icon {
    order: 0;
  }
}
.shipping-block__text {
  order: 0;
}
@media only screen and (min-width: 600px) {
  .shipping-block__text {
    order: 1;
  }
}
.shipping-block__text p:first-child {
  margin-top: 0;
}
.main-product-showcase-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (min-width: 600px) {
  .main-product-showcase-2 {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }
}
.product-item {
  margin-bottom: 1.25em;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .product-item {
    width: 43%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media only screen and (min-width: 800px) {
  .product-item {
    margin-left: 0;
    margin-right: 0;
    width: 30%;
  }
}
.new__title {
  max-width: 50%;
}
@media only screen and (min-width: 800px) {
  .new__title {
    max-width: 100%;
  }
}
.product-item__description {
  width: 100%;
}
.product-item__description_large {
  margin-top: 1.25em;
  margin-bottom: 0.625em;
  line-height: 1.35;
  font-size: 1.6rem;
}
.product-item__link {
  color: rgba(0,0,0,0.95);
  border-bottom: none;
}
.product-item__link:hover {
  border-bottom: none;
}
.product-item__wrapper {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
@media only screen and (max-width: 1050px) {
  .product-item__wrapper {
    flex-direction: column;
  }
}
.product-item__image-wrapper {
  flex: 0 0 150px;
  min-width: 150px;
  margin-right: 1rem;
}
.product-item__image {
  max-width: 100%;
  height: auto;
}
.product-item__title.gamma {
  margin-top: 0;
  color: #bc0a34;
  line-height: 1.25;
}
.product-tile__product-variants {
  margin-top: 1.25em;
  max-width: 15rem;
}
@media only screen and (min-width: 600px) {
  .product-tile__product-variants {
    margin-top: 0.625em;
  }
}
.product-variants__price {
  float: right;
}
.home-categories {
  margin-top: 2.5em;
}
@media only screen and (min-width: 600px) {
  .home-header__container {
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 800px) {
  .home-header {
    margin-bottom: 1.375em;
    font-size: 1.5em;
    max-width: 100%;
  }
}
.discount-announce {
  color: #d90303;
  text-align: center;
  background-color: #fcf2c8;
  padding: 1rem;
  margin-left: 0;
  margin-right: 0;
}
@media only screen and (min-width: 600px) {
  .discount-announce {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.3125rem;
    border-radius: 6px;
  }
}
@media only screen and (min-width: 1050px) {
  .discount-announce {
    margin-left: 0;
    margin-right: 0;
  }
}
.discount-announce.discount-announce__alt {
  margin-top: 2.75em;
  background-color: #fff;
  padding: 0;
}
@media only screen and (min-width: 400px) {
  .discount-announce.discount-announce__alt {
    font-size: 1em;
  }
}
.discount-announce__block {
  display: flex;
}
@media only screen and (min-width: 800px) {
  .discount-announce__block {
    width: 30%;
  }
}
.discount-announce__image {
  width: 48px;
  margin-right: 1.375em;
}
.discount-announce__text {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 600px) {
  .discount-announce__text {
    margin: 1rem;
  }
}
.discount-announce_columns .gamma {
  margin-top: 0;
  line-height: 1.3;
  color: #d90303;
  font-size: 1.7rem;
}
.discount-announce_columns p {
  margin-top: 0.25em;
}
.discount-announce_columns .discount-announce__text {
  text-align: left;
  color: rgba(0,0,0,0.95);
}
@media only screen and (min-width: 800px) {
  .discount-announce_columns {
    padding: 1.25em;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .discount-announce_columns .discount-announce__text {
    margin: 0;
    width: 30%;
  }
  .discount-announce_columns .discount-announce__text+.discount-announce__text {
    margin-top: 0;
  }
}
.discount-announce__alt .gamma {
  font-size: 1.2rem;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 1;
  margin-top: 0;
}
.discount-announce__alt .discount-announce__text {
  width: auto;
  margin: 0;
  margin-bottom: 1.375em;
}
@media only screen and (min-width: 800px) {
  .discount-announce__alt .discount-announce__text {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 400px) {
  .home-header__text {
    display: flex;
    justify-content: space-between;
    margin-top: 2.75em;
  }
}
@media only screen and (min-width: 400px) {
  .home-header__stars {
    margin-right: 1.375em;
  }
}
.stars__rating {
  margin-top: 1.375em;
  display: flex;
  flex-direction: column;
}
.stars__stars {
  letter-spacing: 3px;
  font-size: 2rem;
  font-family: Times;
  line-height: 1;
  margin-bottom: 0.3125em;
  color: #fc0;
}
.covid {
  border: 1.375em solid #fcf2c8;
  padding: 0 1.375em 2.0625em !important;
}
.covid .beta {
  margin-top: 1rem;
  color: #bc0a34;
}
.product-carousel {
  padding: 0.5rem 1rem 0.5rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 1050px) {
  .product-carousel {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    display: flex;
    justify-content: space-between;
  }
  .product-carousel:after {
    content: none;
  }
}
.product-carousel__item {
  display: inline-block;
  padding-right: 1.25em;
  font-size: 0.8rem;
}
@media only screen and (min-width: 1050px) {
  .product-carousel__item {
    padding-right: 0;
  }
}
.product-carousel__item_active {
  color: #444;
}
.product-page-hero {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 600px) {
  .product-page-hero {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1050px) {
  .product-page__title {
    flex: 0 0 60%;
    flex: 1 0 100%;
  }
}
.product-page__image {
  margin-top: 1.25em;
}
.product-page__image img {
  max-width: 100%;
  height: auto;
}
@media only screen and (min-width: 1050px) {
  .product-page__image {
    flex: 0 0 40%;
    text-align: center;
    order: 1;
  }
}
@media only screen and (min-width: 600px) {
  .product-page__description {
    flex: 0 0 100%;
  }
}
@media only screen and (min-width: 1050px) {
  .product-page__description {
    flex: 0 0 55%;
  }
}
.product-page__price-table {
  margin-top: 1.25em;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 600px) {
  .product-page__price-table {
    max-width: 500px;
  }
}
.price-table__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
@media only screen and (min-width: 600px) {
  .price-table__row {
    margin-bottom: 1rem;
  }
}
.price-table__title {
  flex: 2 0 100%;
  font-weight: bold;
}
@media only screen and (min-width: 600px) {
  .price-table__title {
    flex: 0 1 30%;
  }
}
.price-table__price {
  flex: 0 0 25%;
}
.product-page-hero_alternate {
  margin-top: -2em;
  display: flex;
}
@media only screen and (max-width: 800px) {
  .product-page-hero_alternate {
    flex-direction: column;
  }
}
.hero__main-card {
  background-color: #fcf2c8;
  padding: 1.375em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (min-width: 600px) {
  .hero__main-card {
    flex: 0 1 70%;
  }
}
@media only screen and (min-width: 800px) {
  .hero__main-card {
    flex: 0 1 75%;
  }
}
.hero__side-card {
  background-color: #fdf8e4;
  padding: 1.375em;
}
@media only screen and (min-width: 600px) {
  .hero__side-card {
    flex: 0 1 30%;
  }
}
@media only screen and (min-width: 800px) {
  .hero__side-card {
    flex: 0 1 25%;
  }
}
.product-page-base-product .hero__main-card {
  background-color: #fff;
  flex-direction: column-reverse;
}
@media only screen and (min-width: 600px) {
  .product-page-base-product .hero__main-card {
    flex: none;
    flex-direction: row-reverse;
  }
}
@media only screen and (min-width: 800px) {
  .product-page-base-product .hero__main-card {
    flex: 0 1 75%;
    padding: 1.375em 0;
  }
}
.product-page-base-product .hero__side-card {
  background-color: #fefbef;
  border-top: 1px solid #fcf2c8;
}
@media only screen and (min-width: 800px) {
  .product-page-base-product .hero__side-card {
    border-left: 1px solid #fcf2c8;
  }
}
@media only screen and (min-width: 600px) {
  .product-page-base-product .product__description {
    padding-right: 1.375em;
  }
}
@media only screen and (min-width: 600px) {
  .product-page-base-product .product-page__price {
    padding-right: 1.375em;
    flex: 0 0 50%;
  }
}
@media only screen and (min-width: 800px) {
  .product-page-base-product .product-page__price {
    padding-right: 1.375em;
    flex: 0 0 40%;
  }
}
.product-page-base-product .product-image {
  margin-bottom: 1.25em;
}
.product__option {
  margin-bottom: 0.625em;
}
.product__option .option__name {
  margin: 0;
  margin-bottom: 0.3125em;
}
button.product__order-btn {
  margin-top: 0;
}
.option__color {
  display: inline-block;
  cursor: pointer;
  width: 1.875em;
  height: 1.875em;
  border: 2px solid #fff;
  border-radius: 6px;
}
.option__color.selected {
  border: 4px solid #bc0a34;
}
.option__text {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #808080;
  border-radius: 6px;
  margin-right: 0.625em;
  margin-bottom: 0.625em;
  padding: 0.3125em 0.625em;
}
.option__text.selected {
  color: #fff;
  border: 2px solid #bc0a34;
  background-color: #bc0a34;
}
.product-page__variants {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem 1rem 0.5rem;
  flex-direction: column;
}
@media only screen and (min-width: 1050px) {
  .product-page__variants {
    flex-direction: row;
    margin-bottom: 2rem;
  }
}
.product-page__variant {
  align-items: center;
  flex-flow: row wrap;
  white-space: normal;
  margin-bottom: 2.5em;
}
@media only screen and (min-width: 400px) {
  .product-page__variant {
    display: flex;
  }
}
@media only screen and (min-width: 1050px) {
  .product-page__variant {
    flex: 0 0 30%;
    max-width: 75vw;
    width: 75vw;
    margin-right: 10vw;
    margin-right: 1rem;
  }
}
.variant__title {
  margin-top: 0;
  flex: 0 0 100%;
}
@media only screen and (min-width: 600px) {
  .variant__title {
    order: 1;
  }
}
@media only screen and (min-width: 1050px) {
  .variant__title {
    order: 2;
  }
}
@media only screen and (min-width: 800px) {
  .product-page__variants_four {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 800px) {
  .product-page__variants_four .product-page__variant {
    flex: 0 0 22%;
  }
}
@media only screen and (min-width: 600px) {
  .variant__description {
    width: 50%;
    order: 2;
  }
}
@media only screen and (min-width: 1050px) {
  .variant__description {
    width: 100%;
    order: 3;
  }
}
.variant__image {
  max-width: 100%;
  height: auto;
}
.variant__image a {
  border-bottom: none;
}
@media only screen and (min-width: 600px) {
  .variant__image {
    order: 3;
  }
}
@media only screen and (min-width: 1050px) {
  .variant__image {
    order: 1;
  }
}
.free-stamps__container {
  margin-top: 1.25em;
}
.free-stamps__announce {
  display: inline-block;
  background-color: #d90303;
  color: #fff;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  font-family: sans-serif;
  font: 16px 'PT Sans', Helvetica, Arial, sans-serif;
}
.free-stamps__announce_lifted {
  line-height: 1em;
  vertical-align: top;
}
.product-2__hero {
  margin-top: -2rem;
}
.product-2__hero-image {
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 800px) {
  .product-2__hero-columns > .column:last-child {
    flex: 0 0 60%;
  }
  .product-2__hero-columns > .column:first-child {
    flex: 0 0 40%;
  }
}
.product-2__product-description_left {
  padding-left: 10% !important;
}
.product-2__product-description_right {
  padding-right: 10% !important;
}
.metro-list li + li {
  margin-top: 0.625em;
}
.metro-list__name_line-1 {
  color: #ef161d;
}
.metro-list__name_line-2 {
  color: #2dbe2b;
}
.metro-list__name_line-3 {
  color: #0076bb;
}
.metro-list__name_line-4 {
  color: #00bfff;
}
.metro-list__name_line-5 {
  color: #8b5a2b;
}
.metro-list__name_line-6 {
  color: #ed9120;
}
.metro-list__name_line-7 {
  color: #800080;
}
.metro-list__name_line-8 {
  color: #ffde43;
}
.metro-list__name_line-8A {
  color: #ffde43;
}
.metro-list__name_line-9 {
  color: #9a9a9a;
}
.metro-list__name_line-10 {
  color: #97c900;
}
.metro-list__name_line-11 {
  color: #82c0c0;
}
.metro-list__name_line-12 {
  color: #a1b3d4;
}
.metro-list__name_line-15 {
  color: #d0669a;
}
.metro-list__name_line-14 {
  color: #e2635f;
}
.category__title {
  margin-top: 1.25em;
}
.product-tiles {
  margin-top: 1.25em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.product-tile {
  display: block;
  color: rgba(0,0,0,0.95);
  margin-bottom: 2.5em;
}
@media only screen and (min-width: 600px) {
  .product-tile {
    width: 47%;
  }
}
@media only screen and (min-width: 800px) {
  .product-tile {
    width: 30%;
  }
}
@media only screen and (min-width: 1050px) {
  .product-tile {
    width: 22%;
  }
}
.product-tileempty {
  height: 0;
  border: 0;
}
.tile__title {
  margin-top: 0;
  margin-bottom: 0.625em;
}
.tile__description pfirst-child {
  margin-top: 0.625em;
}
.tile__button {
  margin-top: 0.625em;
}
.tile__overlay {
  display: none;
  position: absolute;
  top: -5px;
  left: -5px;
  bottom: -5px;
  right: -5px;
  background-color: rgba(255,255,255,0.9);
  padding: 1rem;
  text-align: center;
  align-content: center;
  align-items: center;
}
.product-tile__muted {
  position: relative;
}
.product-tile__muted .tile__button {
  display: none;
}
.product-tile__muted .tile__overlay {
  display: flex;
}
.page-reviews__grid-sizer,
.page-reviews__review {
  width: 33.333333333333336%;
  height: 200px;
  margin: 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 1050px) {
  .page-reviews__grid-sizer,
  .page-reviews__review {
    width: 50%;
  }
}
@media only screen and (max-width: 800px) {
  .page-reviews__grid-sizer,
  .page-reviews__review {
    height: auto;
    width: 100%;
  }
}
.page-reviews__review-wrapper {
  padding: 3rem 3rem 3.5rem;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.review_s.review_stamp {
  padding: 1.5rem;
  background-color: #fff;
}
.review_s.review_stamp .page-reviews__review-wrapper {
  border: 6px solid #1041c9;
  color: #1041c9;
  font-family: 'Times New Roman', 'Georgia', serif;
  padding: 1rem;
  text-align: center;
  transform: rotate(-6deg);
}
.review_s.review_stamp .review__date {
  color: #1041c9;
}
.review_xs.review_stamp {
  background-color: #fff;
  padding: 2.5rem;
}
.review_xs.review_stamp .page-reviews__review-wrapper {
  border: 6px solid #c91a25;
  color: #c91a25;
  font-family: 'Arial', Helvetica, sans-serif;
  padding: 1rem;
  text-align: center;
  transform: rotate(-6deg);
}
.review_xs.review_stamp .review__date {
  display: none;
}
.review_m {
  height: 400px;
}
.review_m .review__body {
  font-size: 1.5rem;
}
@media only screen and (max-width: 800px) {
  .review_m {
    height: auto;
    width: 100%;
  }
}
.review_l {
  width: 66.66666666666667%;
}
@media only screen and (max-width: 1050px) {
  .review_l {
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .review_l {
    height: auto;
    width: 100%;
  }
}
.review_xl {
  width: 66.66666666666667%;
  height: 400px;
}
.review_xl .review__body {
  font-size: 1.5rem;
}
@media only screen and (max-width: 1050px) {
  .review_xl {
    height: 500px;
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .review_xl {
    height: auto;
    width: 100%;
  }
}
.review_xxl {
  width: 66.66666666666667%;
  height: 400px;
  border: 20px solid #fff;
}
.review_xxl .page-reviews__review-wrapper {
  border: 15px double #fff;
}
@media only screen and (max-width: 1050px) {
  .review_xxl {
    width: 100%;
    height: 500px;
  }
}
@media only screen and (max-width: 800px) {
  .review_xxl {
    height: auto;
    width: 100%;
  }
}
.review_xxxl {
  width: 100%;
  height: 600px;
}
.review_xxxl .page-reviews__review-wrapper {
  border-top: 30px double #fff;
  border-bottom: 30px double #fff;
}
.review_xxxl .review__body {
  font-size: 1.5rem;
}
@media only screen and (max-width: 1050px) {
  .review_xxxl {
    height: 750px;
  }
}
@media only screen and (max-width: 800px) {
  .review_xxxl {
    height: auto;
    width: 100%;
  }
}
.cart__items {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem;
}
.cart-item {
  border-bottom: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
}
.cart-item:last-child {
  border-bottom: none;
}
@media only screen and (min-width: 1050px) {
  .cart-item {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
}
.cart__items_compact {
  border: none;
}
.cart__items_compact > .cart-item {
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  border: none;
}
.cart-item__title {
  margin-top: 0;
}
.cart-item__description {
  flex: 0 0 70%;
}
@media only screen and (min-width: 1050px) {
  .cart-item__description {
    flex: 1 0 40%;
  }
}
.cart-item__files {
  margin-top: 0.5rem;
}
.cart-item__price {
  flex: 0 0 30%;
  text-align: right;
}
@media only screen and (min-width: 1050px) {
  .cart-item__price {
    order: 1;
    flex: 0 1 20%;
  }
}
.spinner {
  display: flex;
}
.spinner__button {
  cursor: pointer;
  color: rgba(128,128,128,0.95);
}
.spinner__button:hover {
  color: #bc0a34;
}
.spinner__amount {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.cart-item__amount {
  margin-top: 0.5rem;
}
@media only screen and (min-width: 1050px) {
  .cart-item__amount {
    flex: 0 1;
    margin-top: 0;
  }
}
.cart-item__trash {
  margin-top: 0.5rem;
  cursor: pointer;
  color: rgba(128,128,128,0.95);
}
@media only screen and (min-width: 1050px) {
  .cart-item__trash {
    flex: 0 1;
    margin-top: 0;
    order: 1;
    margin-left: 1rem;
  }
}
.cart-item__trash:hover {
  color: #bc0a34;
}
.cart-control {
  margin-top: 1.25em;
}
.cart-control__text {
  display: none;
}
@media only screen and (min-width: 600px) {
  .cart-control__text {
    display: inline;
  }
}
body.cart .cart-control,
body.cart-cart_checkout .cart-control {
  display: none;
}
.cart-paperform .header__contacts {
  display: none;
}
.cart__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart__items {
  margin-top: 1.25em;
}
.promocode__form {
  display: flex;
}
.promocode__form .form__group {
  margin-top: 0;
  margin-right: 1.25em;
  display: flex;
  flex-direction: row;
}
.promocode__form .form__group .form__label {
  position: relative;
  margin-right: 1.25em;
  margin-top: 0.5rem;
  margin-left: 0;
  font-size: 1rem;
  display: block;
}
.promocode__form .form__group .form__control {
  margin-top: 0;
  padding: 0.3rem 0.8rem;
}
.promocode__form .btn {
  margin-top: 0;
}
.discount-clock {
  font-size: 10em;
  text-align: center;
  color: #1daf19;
  font-family: 'Oswald', sans-serif;
  line-height: 1.1;
}
@media only screen and (min-width: 600px) {
  .discount-clock {
    font-size: 12em;
  }
}
@media only screen and (min-width: 800px) {
  .discount-clock {
    font-size: 14em;
  }
}
@media only screen and (min-width: 1050px) {
  .discount-clock {
    font-size: 16em;
  }
}
.promocode__container {
  background-color: #fcf2c8;
  padding: 1.25em 2.5em 2.5em;
  border-radius: 6px;
  margin-bottom: 3rem;
  box-shadow: 0 1px 4px rgba(135,55,0,0.4);
  text-align: center;
}
@media only screen and (min-width: 400px) {
  .promocode__container {
    margin: 0 auto;
    width: auto;
    max-width: 500px;
  }
}
.promocode__container .beta {
  margin-top: 0;
}
.cart-contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cart-contents h1 {
  flex: 0 1 100%;
}
.cart-contents .cart__items {
  flex: 0 1 75%;
}
@media only screen and (max-width: 1050px) {
  .cart-contents .cart__items {
    flex-basis: 100%;
  }
}
.cart-contents .product-recommendations {
  flex: 0 1 20%;
}
@media only screen and (max-width: 1050px) {
  .cart-contents .product-recommendations {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1.375em;
  }
}
@media only screen and (max-width: 600px) {
  .cart-contents .product-recommendations {
    flex-direction: column;
  }
}
.cart-contents .product-recommendation {
  background-color: #fcf2c8;
  padding: 0 1.25em 1.25em;
  margin-top: 70px;
  display: flex;
  text-align: center;
  flex-direction: column;
}
.cart-contents .product-recommendation:first-child {
  maring-top: 0;
}
@media only screen and (min-width: 600px) {
  .cart-contents .product-recommendation:first-child {
    margin-top: 70px;
  }
}
@media only screen and (max-width: 1050px) {
  .cart-contents .product-recommendation {
    width: 30%;
    margin-right: 1.375em;
  }
}
@media only screen and (max-width: 800px) {
  .cart-contents .product-recommendation {
    width: 48%;
  }
}
@media only screen and (max-width: 600px) {
  .cart-contents .product-recommendation {
    width: 100%;
    margin-right: 0;
  }
}
.cart-contents .product-recommendation .recommendation__image {
  align-self: center;
  margin-top: -50px;
}
.cart-contents .product-recommendation .recommendation__title {
  margin-bottom: 1.25em;
}
.cart-checkout header {
  justify-content: center;
  display: flex;
}
.cart-checkout .checkout-container > .columns {
  justify-content: center;
}
.cart-checkout .cart__footer {
  justify-content: center;
  text-align: center;
}
.cart-checkout .header__logo {
  flex: 0 1 auto;
}
.article-list {
  margin-top: 1.25em;
}
@media only screen and (min-width: 600px) {
  .article-list {
    display: flex;
    flex-wrap: wrap;
    margin: 1.25em -0.625em;
  }
}
@media only screen and (min-width: 600px) {
  .article-list__item {
    display: block;
    min-height: 420px;
    flex: 0 0 50%;
    border: 0.625em solid #fff;
    background-color: #fcf2c8;
    padding: 1.25em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.3s ease-out;
  }
  .article-list__item:hover {
    background-color: #840724;
    border-bottom-color: #fff;
  }
  .article-list__item:hover .article-list__item-text {
    color: #fff;
  }
}
@media only screen and (min-width: 800px) {
  .article-list__item {
    flex: 0 0 33.333333333333336%;
  }
}
.article-list__item-text {
  transition: all 0.3s ease-out;
}
.article-list__item-date {
  color: #999;
}
.article-list__item-title.beta {
  margin-top: 0;
}
.city-list {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.city-list .city-list_city {
  flex: 1 1 25%;
}
.point {
  display: flex;
  margin-top: 2.5em;
}
.point__number {
  line-height: 1;
  font-weight: bold;
  font-size: 1.5em;
  margin-right: 0.625em;
}
.point__name {
  font-weight: bold;
  line-height: 1;
}
.point__phones {
  margin-top: 0.625em;
}
.point__address {
  margin-top: 0.625em;
}
.point__notes {
  margin-top: 0.3125em;
}
.point__notes p {
  margin-top: 0.3125em;
}
.point__time {
  font-weight: bold;
}
